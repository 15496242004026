// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// .lead-section+.content-section,
// .lead-section+.bundle-detail-section,
// .lead-section+.bundle-overview-section {
// 	margin-top: calc(-6vh + 30px);
// }

// home intro section
.home-intro-section {
	padding-top: 9vh!important;
	margin-bottom: 20vh !important;
	padding-bottom: 0 !important;
	position: relative;
	@include media-breakpoint-down(lg) {
		margin-bottom: 6vh !important;
		padding-bottom: 9vh!important;
	}
	&::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: -18vh;
		width: 100%;
		height: 18vh;
		background: url("/images/arc-intro-section-bottom.svg");
		background-position: top;
		background-size: 100%;
		background-repeat: no-repeat;

		z-index: 100;
	}
	.container {
		&.intro {
			margin-bottom: 6vh;
			.column {
				max-width: 750px;
				text-align: center;
				margin: auto;
			}
		}
		&.grid {
			.grid-items {
				.item {
					max-width: 50% !important;
					flex-basis: 50% !important;
					@include media-breakpoint-down(lg) {
						max-width: 100% !important;
						flex-basis: 100% !important;
					}
					.card {
						border-radius: 6px;
						border: 0;
						border-radius: 6px;
						box-shadow: 0px 0px 10px 0px rgba(#000, 0.25);
						.card-body {
							.card-caption {
								text-align: center;
								max-width: 60%;
								margin: auto;
								@include media-breakpoint-down(lg) {
									max-width: 100%;
								}
								.card-title {
									color: $green-dark;
									font-family: $font-family-secondary;
									font-size: 30px;
									font-style: normal;
									font-weight: 500;
									line-height: normal;
								}
								.card-subtitle {
									font-size: $h6-font-size;
								}
								.list-icons {
									max-width: max-content;
									margin: 1rem auto;
									li {
										justify-content: center;
									}
								}
							}
							.card-buttons {
								text-align: center;
								padding-bottom: 3vh;
							}
						}
					}
				}
			}
		}
	}
}

// home usp section

.home-usp-section {
	padding: 3vh 0;
	.container {
		.container-holder {
			max-width: 85%;
			margin: auto;
			@include media-breakpoint-down(lg) {
				max-width: 100%;
			}
		}
		&.intro {
			margin-bottom: 3vh;
			.column {
				text-align: center;
			}
		}
		&.grid {
			.grid-items {
				.item {
					padding-left: 7px;
					padding-right: 7px;
					margin-bottom: 15px;
					.card {
						background: $blue;
						border-radius: 6px;
						border: 0;
						aspect-ratio: 1.4/1;
						.card-body {
							padding-left: .5rem;
							padding-right: .5rem;
						}
						.card-image {
							padding: 1.2rem 0 0 0;
							i {
								color: $body-bg;
								font-size: 40px;
								font-style: normal;
								font-weight: 400;
								line-height: normal;
							}
						}
						.card-title {
							color: $body-bg;
							font-family: $font-family-secondary;
							font-size: 20px;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
							text-align: center;
						}
						.card-subtitle {
							display: none;
						}
						.card-description {
							text-align: center;
							color: $white;
							text-align: center;
							font-size: 15px;
							font-style: normal;
							font-weight: 400;
							line-height: 24px; /* 160% */
						}
					}
					&:hover {
						.card {
							transform: scale(1.07);
							transition: 0.5s ease;
							border-radius: 6px;
							box-shadow: 0px 0px 10px 0px rgba(#000, 0.15);
						}
					}
				}
			}
			.links {
				text-align: center;
				margin: 6vh auto;
				@include media-breakpoint-down(lg) {
					a {
						margin-bottom: 0.5rem;
					}
				}
			}
		}
	}
}

// home banner section

.home-banner-section {
	.container {
		&.banner {
			margin-bottom: 6vh;
			.owl-carousel {
				border-radius: 6px;
				padding-bottom: 6vh;
				overflow: hidden;
				.owl-stage-outer {
					overflow: visible;
				}
				.owl-dots {
					display: none;
				}
				.owl-nav {
					max-width: 72%;
					position: relative;
					z-index: 950;
					margin: auto;
					@include media-breakpoint-down(lg) {
						max-width: 96%;
					}
					button {
						i {
							color: $purple;
							font-size: 20px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							&::before {
								content: "\f138";
							}
						}
						&.owl-prev {
							i::before {
								content: "\f137";
							}
						}
					}
				}
				.owl-item {
					min-height: 65vh;
					border-radius: 6px;
					box-shadow: 0px 0px 10px 0px rgba(#000, 0.15);
					.item {
						padding: 0;
					}

					.owl-caption-holder {
						height: 100%;
						margin: 0;
						display: flex;
						align-items: flex-end;
						.owl-caption {
							background: $white;
							height: 9vh;
							max-width: 75%;
							margin: 0 auto;
							position: relative;
							display: flex;
							align-items: center;
							border-radius: 50px;
							transform: translateY(50%);
							@include media-breakpoint-down(lg) {
								max-width: 100%;
								padding: 1.2rem;
							}
							.owl-title {
								position: absolute;
								left: 50%;
								top: 0;
								background: $green;
								color: $white;
								transform: translate(-50%, -75%);
								color: #fff;
								font-family: $font-family-secondary;
								font-size: 16px;
								font-style: normal;
								font-weight: 500;
								line-height: normal;
								padding: 10px 50px;
								text-align: center;
								border-radius: 35px;
								@include media-breakpoint-down(lg) {
									font-size: 12px;
									padding: 10px;
									width: 80%;
								}
								&::before {
									content: url("/images/grass-green.svg");
									position: absolute;
									left: 50%;
									top: 0;
									transform: translate(-50%, -85%);
									z-index: -1;
								}
							}
							.owl-subtitle {
								display: none;
							}
							.owl-description {
								margin: 0 auto;
								color: $blue;
								font-family: $font-family-secondary;
								font-size: 16px;
								font-style: normal;
								font-weight: 500;
								line-height: normal;
								@include media-breakpoint-down(lg) {
									max-width: 100%;
									padding: 0.5rem;
									font-size: 11px;
								}
							}
							.owl-btn {
								display: none;
							}
						}
					}
				}
			}
		}
		&.info {
			.container-holder {
				max-width: 85%;
				margin: auto;
				@include media-breakpoint-down(lg) {
					max-width: 100%;
				}
				.title {
					margin-bottom: 0.75rem;
				}
				li {
					&::before {
						color: $blue;
					}
				}
			}
		}
	}
}

// lead-section
// =========================================================================
&:not(.accommodation-detail) {
	.lead-section {
	}
}
.lead-section {
	margin: 6vh 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;

			@extend .list-unstyled;

			li {
				margin: 0 15px 0 0;
				background: $green;
				color: $body-bg;
				padding: 7px;
				border-radius: 6px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
