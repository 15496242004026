// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $green-light;
	transition: 0.5s;
	border-bottom: 8px solid $green;

	&.sticky {
		box-shadow: $shadow;
		border-bottom: 3px solid $green;
		.container,
		.container-fluid {
			.container-holder {
				> .column {
					height: 12vh;
					transition: 0.5s ease;
					align-items: center;
					opacity: 1;

					@starting-style {
						opacity: 0;
						align-items: center;
					}

					.logo {
						width: 90px;
						transition: 0.5s ease;
						max-width: 90px;
						@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 144dpi) {
							img {
								margin-top: -10px;
								transform: scale(
									0.7
								); 
							}
						}
					}
				}
			}
		}
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: flex-start;
				justify-content: center;
				padding: 0.5rem 1.5rem;
				position: relative;
				height: 170px;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;

					height: unset;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		@include media-breakpoint-down(lg) {
			flex-basis: 30%;
			padding: 0;

			.navbar-toggler {
				padding: 0;
				i {
					color: #1e5b66;
					font-size: 20px;
					font-style: normal;
					font-weight: 900;
					line-height: normal;

					transition: 0.5s ease-out;
				}
				&[aria-expanded="true"] {
					i {
						transform: rotate(-360deg);
						transition: 0.5s ease-out;
						&::before {
							content: "\f00d";
						}
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 191px;
		position: absolute;
		left: 0;
		right: 0;
		top: 0.75rem;
		margin: auto;
		transition: 0.5s ease;
		@include media-breakpoint-down(lg) {
			width: 100px;
			position: relative;
			margin: 0 auto 1rem auto;
			flex-basis: 30%;
		}
		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 2;
			padding: 6vh 1rem 3vh 1rem;

			.navbar-nav {
				.nav-item {
					border-bottom: 1px solid $green;

					.nav-link {
						font-family: $font-family-primary;
						color: $blue;
						font-size: 16px;
						font-weight: 600;
					}

					&.active {
						.nav-link {
							color: $purple !important;
							font-weight: 600;
						}
					}

					.dropdown-menu {
						position: static !important;
						border: 0 !important;
						box-shadow: none !important;
						margin: 0 !important;
						padding: 0 !important;
						transform: none !important;
						background: none !important;

						&::after,
						&::before {
							display: none;
						}

						.nav-item {
							border-bottom: 0;
							padding-left: 1rem;
						}

						.nav-link {
							font-size: 16px;
							font-weight: 200;
							font-family: $font-family-primary;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			margin-right: auto;
			.navbar-nav {
				gap: 1.5rem;
				padding: 10px 0;
				.nav-item {
					.nav-link {
						color: $blue;
						font-size: 15px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						display: flex;
						align-items: center;
						gap: 0.35rem;
						text-underline-offset: 7px;
					}
					&:hover,
					&.active {
						.nav-link {
							text-decoration: underline;
							color: $purple;
						}
					}
					.dropdown-menu.show {
						background: $green-light;
						border: 0;
						box-shadow: none;
						min-width: 10rem;
						text-align: center;
						&::before,
						&::after {
							display: none;
						}
						.nav-item {
							text-align: center;
							display: flex;
							justify-content: center;
							.nav-link {
								color: $blue !important;
								text-decoration: none !important;
								text-align: center !important;
							}
							&.active,
							&:hover {
								.nav-link {
									text-decoration: underline !important;
									color: $purple !important;
								}
							}
						}
					}
				}
			}
		}
	}
	// contact options
	div.contact-options {
		@include media-breakpoint-down(lg) {
			display: none;
		}
		margin-right: 1.75rem;
		ul {
			display: flex;
			align-items: center;
			gap: 0.75rem;
			height: 100%;
			padding: 10px 0;
			li,
			a {
				color: $blue;
				font-size: 15px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				text-decoration: none;
				text-underline-offset: 7px;
				&:hover {
					text-decoration: underline;
				}
				&:has(i) {
					&:hover {
						text-decoration: none;
						i::before {
							font-weight: 900 !important;
						}
					}
					.list-item-title {
						display: none;
					}
					i {
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}
		}
	}
	// book-button
	div.book-button {
		@include media-breakpoint-down(lg) {
			flex-basis: 30%;
			ul {
				display: flex;
				justify-content: flex-end;
				text-align: right;
			}
			a {
				padding: 7px 15px !important;
			}
		}
		a {
			@extend .btn, .btn-primary;
		}
	}
	// contact-menu
	div.contact-menu,
	div.social-menu {
		order: 3;
		display: none;
	}
	&:has(.navbar-toggler[aria-expanded="true"]) {
		div.contact-menu,
		div.social-menu {
			margin-right: auto;
			margin-left: 1rem;
			display: block;
			margin-bottom: 3vh;
			flex-basis: 40%;
			ul {
				display: flex;
				margin-right: auto;
				align-items: center;
				justify-content: space-between;
				gap: 1.2rem;
				height: 100%;
				padding: 10px 0;
				li,
				a {
					color: $blue;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					text-decoration: none;
					text-underline-offset: 7px;
					&:hover {
						text-decoration: underline;
					}
					&:has(i) {
						&:hover {
							text-decoration: none;
							i::before {
								font-weight: 900 !important;
							}
						}
						.list-item-title {
							display: none;
						}
						i {
							font-size: 20px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}
					}
				}
			}
		}
		div.contact-menu ul {
			justify-content: flex-start;
		}
		div.social-menu ul {
			justify-content: flex-end;
		}
	}
}
