// eyecatcher
section.eyecatcher {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		pointer-events: none;
		background: url("/images/arc-eyecatcher.svg");
		background-position: bottom;
		background-size: 100%;
		background-repeat: no-repeat;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 600px;
		z-index: 100;
	}
	&.large {
		&::after {
			content: "";
			position: absolute;
			pointer-events: none;
			background: url("/images/arc-eyecatcher-large.svg");
			background-position: bottom;
			background-size: 100%;
			background-repeat: no-repeat;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 600px;
			z-index: 100;
		}
	}
}
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-title {
		color: #fff;
		text-shadow: 0px 0px 10px rgba(#000, 0.25);
		font-family: $font-family-secondary;
		font-size: 48px;
		font-style: normal;
		font-weight: 500;
		line-height: 48px;
		/* 100% */
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 45vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 75vh;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	// vimeo-video
	.vimeo-video {
		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				width: 177.78vh;
			}
		}
	}
}
