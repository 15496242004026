// mini-sab
.mini-sab {
	margin-top: -16vh;
	@include media-breakpoint-down(lg){
	margin-top: -9vh;
	background: $green-light;
	}
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			border-radius: 4px;
			background: $blue;
			border-radius: 50px;
			color: #fff;
			padding: 1rem;
			@include media-breakpoint-down(lg){
				max-width: 95%;
				border-radius: 15px;
			}
			.tommy-zeb-wrapper-mini{
				margin-top: 0.5rem;
			}
		}
	}
	.mini-search-book {
		position: relative;
		.heading {
			position: absolute;
			left: 50%;
			top: 0;
			transform: translate(-50%, -85%);
			background: $green;
			border-radius: 35px;
			color: #fff;
			font-family: $font-family-secondary;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			padding: 10px 30px;
			@include media-breakpoint-down(lg){
				min-width: 90%;
				text-align: center;
			}
			&::before {
				content: url("/images/grass-green.svg");
				position: absolute;
				left: 50%;
				top: 0;
				transform: translate(-50%, -85%);
				z-index: -1;
			}
		}
	}
}
