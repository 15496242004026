// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green-light:#E1EAD7;
$green-dark:#375547;
$green: #019E57;
$purple:#924F7D;
$blue:#1E5A67;
$white: #fff;
$black: #000;

// theme-colors
$primary: $purple;
$secondary: $green;

// body
$body-bg: #F5F9F0;
$body-color: $black;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;700&display=swap');
$font-family-primary: 'Roboto', sans-serif;
$font-family-secondary: 'Roboto Slab', sans-serif;

$font-family-base: $font-family-primary;

$font-size-base: 0.9375rem; // 15px

$h1-font-size: $font-size-base * 2.6667; // 40px
$h2-font-size: $font-size-base * 2.1333; // 32px
$h3-font-size: $font-size-base * 1.8667; // 28px
$h4-font-size: $font-size-base * 1.6; // 24px
$h5-font-size: $font-size-base * 1.3333; // 20px
$h6-font-size: $font-size-base; // 15px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 500;
$headings-color: $green-dark;

// contextual
$light: $green-light;
$dark: $blue;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $secondary;
$matrix-color-secondary: $blue;

// shadow
$shadow: none;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
