// footer
.footer {
	overflow: hidden;
	section {
		&.footer-top {
			padding: 6vh 0;
			position: relative;
			&::before {
				content: "";
				background: url("/images/footer-arc.svg");
				background-repeat: no-repeat;
				background-size: 100%;
				background-position: top;
				position: absolute;
				bottom: -14vh;
				left: 0;
				width: 105%;
				height: 14vh;
				z-index: 800;
				

				pointer-events: none;
			}

			.image {
				display: flex;
				justify-content: center;
				img {
					width: 190px;
					margin: auto;
				}
			}
		}
		&.footer-bottom {
			padding-top: 18vh;
			padding-bottom: 9vh;
			min-height: 50vh;
			background-color: $blue;
			position: relative;
			&::after {
				content: url("/images/grass-blue.svg");
				position: absolute;
				top: 7vh;
				left: 50%;
				transform: translate(-50%, 0);
				z-index: 900;
				display: flex;
				align-items: flex-start;
				pointer-events: none;
				
				@include media-breakpoint-down(lg){
					top: 1vh;
					max-width: 100%;
					overflow: hidden;
					transform: translate(-50%, 0) scale(0.99);
					
					
				}
				@include media-breakpoint-down(md){
					top: 0vh;
					max-width: 100%;
					overflow: hidden;
					transform: translate(-50%, 0) scale(0.75);
					
					
				}
				@include media-breakpoint-down(sm){
					top: 0vh;
					max-width: 100%;
					overflow: hidden;
					transform: translate(-50%, 0) scale(0.75);
					
					
				}
				@include media-breakpoint-down(xs){
					top: -4vh;
					max-width: 100%;
					overflow: hidden;
					transform: translate(-50%, 0) scale(0.75);
					
					
				}
			}
			.column {
				text-align: center;
				.footer-text {
					color: $white;

					h1,
					h2 {
						color: $body-bg;
						font-size: 36px;
						margin-bottom: 0;
					}
					h5,
					h6 {
						font-size: 16px;
						color: $body-bg;
						margin-bottom: 0.75rem;
					}
					p {
						color: #fff;

						font-size: 15px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px;
						a {
							text-decoration-line: underline;
							text-decoration-style: solid;
							text-decoration-skip-ink: auto;
							text-decoration-thickness: auto;
							text-underline-offset: auto;
							text-underline-position: from-font;
							color: $white;
							&:hover {
								opacity: 0.8;
							}
						}
					}
				}
				.footer-contact {
					margin-top: 3vh;
					ul {
						display: flex;
						justify-content: center;
						gap: 1rem;
						li {
							width: 70px;
							aspect-ratio: 1/1;
							background: $body-bg;
							align-content: center;
							border-radius: 50%;
							&:hover {
								transform: translateY(-7px);
								transition: 0.35s ease;
							}
						}
						a {
							color: $blue;
							display: flex;
							flex-flow: column;
							justify-content: center;
							align-items: center;
							text-decoration: none;
							i {
								color: #1e5a67;
								font-size: 20px;
								font-style: normal;
								font-weight: 900;
								line-height: normal;
							}
							.list-item-title {
								font-family: $font-family-secondary;
								font-size: 14px;
								font-style: normal;
								font-weight: 500;
								line-height: normal;
							}
						}
					}
				}
			}
		}
		&.footer-socket {
			padding: 0.75rem;
			.list {
				display: flex;
				justify-content: center;
				gap: 1.25rem;
				a {
					color: $blue;
					text-align: center;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
